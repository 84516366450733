<template>
  <component :is="lectureData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="lectureData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching event data
      </h4>
      <div class="alert-body">
        No lecture found with this event id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-lectures-list'}"
        >
          Lectures List
        </b-link>
        for other lectures.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <validation-provider
              #default="validationContext"
              name="English Title"
              rules="required"
          >
            <b-form-group
                label="Title En"
                label-for="mc-title-en"
            >
              <b-form-input
                  v-model="lectureData.title_en"
                  id="mc-title-en"
                  placeholder="Title En"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="lectureData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
              #default="validationContext"
              name="English Description"
              rules="required"
          >
            <b-form-group
                label="English Description"
                label-for="description-en"
            >
              <b-form-textarea
                  id="description-en"
                  v-model="lectureData.description_en"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Arabic Description"
              label-for="description-ar"
          >
            <b-form-textarea
                id="description-ar"
                v-model="lectureData.description_ar"
                autofocus
                trim
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Select Video Type"
              label-for="mc-is-active"
          >
            <v-select
                v-model="lectureData.video_type"
                :reduce="val => val.value"
                @input="changeValue"
                :options="video_type_options"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="lectureData.video_type == 'upload'">
          <b-form-group
              label="Select Lecture Video"
              label-for="mc-is-active"
          >
            <validation-provider
                #default="{ errors }"
                name="Lecture Video"
                rules="required"
            >
              <b-form-file
                  v-model="lectureData.file"
                  :disabled="show"
                  :id="'wildcard-video'"
                  accept="video/mp4,video/x-m4v,video/*"
                  @change="uploadVideoToVimeo($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" v-else>
          <b-form-group
              label="Select Lecture URL"
              label-for="mc-is-active"
          >
            <validation-provider
                #default="{ errors }"
                name="Lecture Video"
                rules="required|url"
            >
              <b-form-input
                  :id="'url-video'"
                  v-model="lectureData.url"
                  :state="errors.length > 0 ? false:null"
                  type="url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Video Thumbnail"
              label-for="thumb"
          >
            <file
                title="Select Thumbnail"
                :key="rerender_component"
                :default_place_holder_src="lectureData.image"
                @file_uploaded="({media})=>{lectureData.image= media.url; lectureImageId = media.id}"
            ></file>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- Course -->
          <validation-provider
              #default="validationContext"
              name="Choose Course"
              rules="required"
          >
            <b-form-group
                label="Choose Course"
                label-for="course"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="lectureData.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="courseOptions"
                  @input="val => updateCourseID(val)"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="categoriesOptions.length > 0"
              #default="validationContext"
              name="Choose Category"
              rules="required"
          >
            <b-form-group
                label="Choose Category"
                label-for="category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="lectureData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  @input="val => updateCategoryId(val)"
                  :clearable="false"
                  input-id="category-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="lectureData.subs.length > 0"
              #default="validationContext"
              name="Choose Sub Category"
              rules="required"
          >
            <b-form-group
                label="Choose Sub Category"
                label-for="sub-category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="lectureData.sub_category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="lectureData.subs"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="val => updateSubCategoryId(val)"
                  input-id="category-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Free Content"
              label-for="is_free_content"
          >
            <b-form-checkbox
                id="is_free_content"
                v-model="lectureData.is_free_content"
                :checked=lectureData.is_free_content
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="lectureData.is_active"
                :checked=lectureData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import lectureStoreModule from '../lectureStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert, BButton, BCard, BCol, BForm, BFormInvalidFeedback,BFormCheckbox, BFormGroup, BFormFile, BFormInput, BLink, BRow,BFormTextarea} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required, alphaNum, email,url } from '@validations'
import vSelect from 'vue-select'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BCard,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  created() {
    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
          let course_id = this.lectureData.course_id
          this.$http.get('/categories?course_id='+course_id)
              .then(response => {
                let that = this
                this.lists = response.data.data.map(function(item){
                  that.categoriesOptions.push({
                    label : item.label,
                    value: item.value+""
                  })
                })
              })
        })
  },
  methods:{
    updateSubCategoryId(val){
      this.lectureData.sub_category_id = val
    },
    updateCategoryId(category_id){
      this.lectureData.category_id = category_id
      this.lectureData.subs.splice(0)
      this.$http.get('/sub-categories?'+'course_id='+this.lectureData.course_id+'&category_id='+category_id)
          .then(response => {
            let that = this
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.lectureData.subs.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    updateCourseID(course_id){
      this.lectureData.course_id = course_id
      this.$http.get('/categories?course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.lectureData.subs.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
      this.updateCategoryId(this.lectureData.category_id)
    },
    setImage(imageId) {
      this.lectureData.video_thumb = imageId
    },
    changeValue(){
      let val = this.lectureData.video_type
      this.lectureData.video_type = val
    },
    uploadVideoToVimeo(event){
      this.show = true
      let data = new FormData();
      data.append('name', 'my-pdf');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('lectures/upload-video',
          data,
          config
      ).then(response => {
        this.lectureData.path = response.data.url
        this.show = false
      })
    },
  },
  data(){
    return{
      required,
      url,
      courseOptions: [],
      rerender_component: 0,
      show: false,
      lists: [],
      categoriesOptions: [],
      video_type: null,
      video_type_options: [
        {
          label : "Upload video",
          value: "upload"
        },
        {
          label : "Insert a URL",
          value: "url"
        }
      ],
    }
  },
  setup() {
    const blankLectureData = {
      title_en: '',
      title_ar: '',
      video_type: '',
      description_en: '',
      description_ar: null,
      is_free_content: null,
      video_thumb: '',
      course_id: '',
      url: null,
      image: null,
      category_id: '',
      video: null,
      path: null,
      file: null,
      sub_category_id: '',
      subs: []
    }
    const lectureImageId = ref(null);
    const toast = useToast()

    const lectureData = ref(JSON.parse(JSON.stringify(blankLectureData)))
    const resetLectureData = () => {
      lectureData.value = JSON.parse(JSON.stringify(blankLectureData))
    }

    const EVENT_APP_STORE_MODULE_NAME = 'lecture'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, lectureStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('lecture/fetchLecture', {id: router.currentRoute.params.id})
        .then(response => {
          lectureData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            lectureData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: lectureData.value.title_en,
        description_en: lectureData.value.description_en,
        title_ar: lectureData.value.title_ar,
        description_ar: lectureData.value.description_ar,
        is_active: lectureData.value.is_active,
        is_free_content: lectureData.value.is_free_content,
        url: lectureData.value.url,
        image: lectureData.value.image,
        course_id: lectureData.value.course_id,
        category_id: lectureData.value.category_id,
        sub_category_id: lectureData.value.sub_category_id,
      }
      if (lectureImageId.value != null) {
        data.image = lectureImageId.value
      }
      store.dispatch('lecture/updateLecture', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-lectures-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Lecture Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetLectureData)
    return {
      onUpdate,
      lectureData,
      lectureImageId,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
